import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { ContactForm } from "../components/Forms"
import { Text, Image } from "../components/Core"
import UpRightArrow from "../../static/images/up-right-arrow.svg"

const ContactPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const { heading, formHeading } = post

  return (
    <Layout navSpacer className="contact-page" path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section zeroTop>
        <Container className="contact-page__hero">
          <Text as="h1" text={heading} />
          <UpRightArrow />
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="contact-page__columns">
            <div>
              <Text text={formHeading} />
              <ContactForm
                classNames="mt-2 form-light"
                redirect="/thank-you/"
              />
            </div>
            <div className="is-hidden-mobile">
              <Image width="570" height="724" publicId={post.image} />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query contactPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        formHeading
        image
      }
    }
    locations: allLocationsJson(sort: { fields: order }) {
      nodes {
        cityName
        cityZip
        name
        stateAbbr
        streetName
        streetNumber
        googlePlaceLink
        phoneNumber
        email
      }
    }
  }
`
